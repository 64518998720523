

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';

import { IService, IServiceResponse } from '@/interfaces/service';
import { ITag } from '@/interfaces/tag';
import { IClient } from '@/interfaces/client';
import { IFlow } from '@/interfaces/flow';
import subservices from './partials/Subservices.vue';
import prices from './partials/Prices.vue';
import clients from './partials/Clients.vue';

const component = Base.extend({
    components: {
        clients,
        prices,
        subservices,
    },
    beforeRouteUpdate(to: any, from: any, next: Function) {
        this.service = undefined;
        this.getService(to.params.id, to.params.client_id);
        next();
    },
    data() {
        return {
            isAddTaskFlowModalActive: false,
            isAddTaskChecklistFlowModalActive: false,
            newFlows: [] as Array<IFlow>,
            newChecklistFlows: [] as Array<IFlow>,
            activeTab: 'general',
            service: undefined as any | IService,
            currentLanguage: 'en',
            draggingRow: undefined as any,
            draggingRowIndex: undefined as any,
            formsTypes: [
                { name: 'ALG1' },
                { name: 'ASB1' },
                { name: 'ASB2' },
                { name: 'BB' },
                { name: 'BD1' },
                { name: 'BD2.1' },
                { name: 'BD2.2' },
                { name: 'BD3' },
                { name: 'BD3.1' },
                { name: 'BD3.2' },
                { name: 'BD3.3' },
                { name: 'BDT' },
                { name: 'CG01' },
                { name: 'DM1' },
                { name: 'DO' },
                { name: 'DP' },
                { name: 'EK1' },
                { name: 'EK1-2-3' },
                { name: 'EK1immo' },
                { name: 'EK1M' },
                { name: 'EK2.1' },
                { name: 'EK2.2' },
                { name: 'EK2A' },
                { name: 'EK2B' },
                { name: 'EK2C' },
                { name: 'EK3.1' },
                { name: 'EK3.2' },
                { name: 'EK3.3' },
                { name: 'EK3.4' },
                { name: 'EK3.5' },
                { name: 'EK4' },
                { name: 'EK4-5' },
                { name: 'EK4.1' },
                { name: 'EK4.2' },
                { name: 'EK4.3' },
                { name: 'EK4.4' },
                { name: 'EK4.5' },
                { name: 'EK4.6' },
                { name: 'EK4M' },
                { name: 'EK5' },
                { name: 'EK6' },
                { name: 'EK6-7' },
                { name: 'EK8' },
                { name: 'EPB' },
                { name: 'EPC' },
                { name: 'EPCVV' },
                { name: 'EPCW' },
                { name: 'EV1' },
                { name: 'EV2' },
                { name: 'EV3' },
                { name: 'GK1.0' },
                { name: 'GK2' },
                { name: 'GK2M' },
                { name: 'GK3' },
                { name: 'GK3.archive' },
                { name: 'GK3M' },
                { name: 'GK4' },
                { name: 'GK5' },
                { name: 'GK6' },
                { name: 'GS1' },
                { name: 'GS2' },
                { name: 'HH-A1' },
                { name: 'HH-A2' },
                { name: 'HH-B1.1' },
                { name: 'HH-B1.2' },
                { name: 'HH-B2.1' },
                { name: 'HH-B3' },
                { name: 'HH-C1' },
                { name: 'HH-C10' },
                { name: 'HH-C11' },
                { name: 'HH-C12' },
                { name: 'HH-C13' },
                { name: 'HH-C2' },
                { name: 'HH-C3' },
                { name: 'HH-C4' },
                { name: 'HH-C5' },
                { name: 'HH-C6' },
                { name: 'HH-C7' },
                { name: 'HH-C8' },
                { name: 'HH-C9' },
                { name: 'HH-D1' },
                { name: 'HH-D2' },
                { name: 'HH-D3' },
                { name: 'HH-D4' },
                { name: 'HH-D5' },
                { name: 'HH-D6' },
                { name: 'HH-E1' },
                { name: 'HH-E10' },
                { name: 'HH-E2' },
                { name: 'HH-E3' },
                { name: 'HH-E4' },
                { name: 'HH-E5' },
                { name: 'HH-E6' },
                { name: 'HH-E7' },
                { name: 'HH-E8' },
                { name: 'HH-E9' },
                { name: 'HH-F1' },
                { name: 'HH-F2' },
                { name: 'HH-F3' },
                { name: 'MG1' },
                { name: 'MG1M' },
                { name: 'MV1.1' },
                { name: 'MV2.1' },
                { name: 'OCV' },
                { name: 'OPL' },
                { name: 'PLB' },
                { name: 'PLBH' },
                { name: 'RK' },
                { name: 'RKA' },
                { name: 'RKV' },
                { name: 'RQCPV01' },
                { name: 'RQCW01' },
                { name: 'RQCZB01' },
                { name: 'SF-1' },
                { name: 'STKB' },
                { name: 'STKO' },
                { name: 'TH1' },
                { name: 'TH2' },
                { name: 'VC' },
                { name: 'VCA01' },
                { name: 'VCA02' },
                { name: 'VV' },
                { name: 'VV.01' },
                { name: 'VV.02' },
                { name: 'VVG' },
                { name: 'W' },
                { name: 'WK' },
            ],
            billingNames: {
                client: 'Client',
                owner: 'Owner',
                tenant: 'Tenant',
                other: 'Other',
            },
        };
    },
    computed: {
        totalBillings() {
            let total = 0;
            if (this.service && this.service.billings) {
                this.service.billings.forEach((bill: any) => {
                    total += parseFloat(bill.percent);
                });
            }

            return Math.round(total);
        },
    },
    mounted() {
        this.getService(
            parseInt(this.$route.params.id, 0),
            parseInt(this.$route.params.client_id, 0),
        );
    },
    methods: {
        addTaskFlows() {
            if (!this.service.task_flows) this.service.task_flows = [];

            this.newFlows.forEach((f:IFlow) => {
                this.service.task_flows.push(f);
            });

            this.newFlows = [];
            this.isAddTaskFlowModalActive = false;
        },
        addTaskChecklistFlows() {
            if (!this.service.task_checklist_flows) this.service.task_checklist_flows = [];

            this.newChecklistFlows.forEach((f:IFlow) => {
                this.service.task_checklist_flows.push(f);
            });

            this.newChecklistFlows = [];
            this.isAddTaskChecklistFlowModalActive = false;
        },
        removeTaskFlow(index: number) {
            this.$delete(this.service.task_flows, index);
        },
        removeTaskChecklistFlow(index: number) {
            this.$delete(this.service.task_checklist_flows, index);
        },
        dragstart(p: any) {
            const payload = p;
            this.draggingRow = payload.row;
            this.draggingRowIndex = payload.index;
        },
        dragover(p: any) {
            const payload = p;

            payload.event.target.closest('tr').classList.add('is-selected');
            payload.event.preventDefault();
        },
        dragleave(p: any) {
            const payload = p;
            payload.event.target.closest('tr').classList.remove('is-selected');
            payload.event.preventDefault();
        },
        drop(p: any) {
            this.service.units.splice(
                p.index,
                0,
                ...this.service.units.splice(this.draggingRowIndex, 1),
            );
            this.service.units.forEach((u: any, index: number) => {
                const unit = u;
                unit.position = index + 1;
            });
        },
        isUnique(type: string) {
            return this.service.billings.filter((b: any) => b.type === type).length < 1;
        },
        removeUnit(index: number) {
            this.$delete(this.service.units, index);
        },
        addUnit() {
            this.service.units.push({
                id: null,
                is_object_related: false,
                translations: {
                    name: { en: '' },
                    label: { en: '' },
                },
            });
        },
        addBilling() {
            this.service.billings.push({
                id: null,
                service_id: this.service.id,
                name: '',
                label: '',
                percent: 0,
            });

            this.service.billings.forEach((bill: any) => {
                const b = bill;
                b.percent = 100 / this.service.billings.length;
            });

            if (this.service.billings.length <= 1) {
                this.service.splitting_behavior = 'never';
            }
        },
        removeBilling(index: number) {
            this.$delete(this.service.billings, index);
            this.service.billings.forEach((bill: any) => {
                const b = bill;
                b.percent = 100 / this.service.billings.length;
            });

            if (this.service.billings.length <= 1) {
                this.service.splitting_behavior = 'never';
            }
        },
        changeClient(client: IClient): void {
            if (client) {
                this.$router.push({
                    name: 'services.edit',
                    params: { id: this.service.id, client_id: client.id },
                } as any);
            }
        },

        getService(id: number, client_id: number | any = undefined): void {
            this.service = undefined;
            let url = `services/${id}`;
            if (client_id) {
                url = `clients/${client_id}/services/${id}`;
            }

            this.get<IServiceResponse>(url).then(({ data }) => {
                this.service = data.data;
                this.activeTab = 'general';
            });
        },
        uploadServicePicture(): void {
            const formData = new FormData();

            if (this.service.picture) {
                formData.append('picture', this.service.picture as any);
            }

            this.post<IServiceResponse>(
                `services/${this.$route.params.id}/picture`,
                formData,
            )
                .then(({ data }) => {
                    this.service.picture_url = data.data.picture_url;
                    this.$emit('success', { message: 'picture updated' });
                })
                .finally(() => {
                    this.service.picture = null;
                });
        },
        saveService(): void {
            let url = `services/${this.$route.params.id}`;
            if (this.$route.params.client_id) {
                url = `clients/${this.$route.params.client_id}/services/${this.$route.params.id}`;
            }

            this.put<IServiceResponse>(url, {
                ...this.service,
                source_id: this.service.source ? this.service.source.id : null,
                category_id: this.service.category ? this.service.category.id : null,
                building_type_id: this.service.building_type
                    ? this.service.building_type.id
                    : null,
                tags: this.service.tags.map((t: ITag) => t.id),
                task_flows: this.service.task_flows.map((f: IFlow) => f.id),
                billings: this.service.billings.map((billing: any) => ({
                    id: billing.id,
                    service_id: billing.service_id,
                    type: billing.type,
                    percent: billing.percent,
                })),
            }).then(({ data }) => {
                this.service = data.data;
                this.$emit('success', { message: 'service updated' });
            });
        },
    },
});

export default component;
