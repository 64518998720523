


















































































































































































































































































































import Base from '@/mixins/Base.vue';
import { IService } from '@/interfaces/service';
import { IClientsResponse, IClient } from '@/interfaces/client';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        service: {
            type: Object as () => IService,
            default: null,
        },
    },
    mounted() {
        this.getServiceClients();
    },
    data() {
        return {
            isAddClientsModalActive: false,
            params: {
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,
            },
            clients: {
                data: [] as Array<IClient>,
                meta: {} as IPagination | any,
            } as IClientsResponse,
            linkedClients: [] as Array<IClient>,
        };
    },
    methods: {

        getServiceClients(page: number = 1): void {
            this.params.page = page;

            const url = `services/${this.$route.params.id}/clients`;

            this.get<IClientsResponse>(url, {
                params: this.params,
            }).then(({ data }) => {
                this.clients = data;

                this.service.clients_count = this.clients.meta.total;
            });
        },
        addServiceClients(): void {
            const url = `services/${this.$route.params.id}/clients`;

            this.post(url, {
                client_id: this.linkedClients.map((c: IClient) => c.id),
            }).then(() => {
                this.$emit('success', { message: 'clients attached' });
                this.getServiceClients();
                this.isAddClientsModalActive = false;
            });
        },
        detachServiceClients(client: IClient): void {
            this.confirm().then(() => {
                const url = `services/${this.$route.params.id}/clients/${client.client_service.id}`;

                this.delete(url, {

                }).then(() => {
                    this.$emit('success', { message: 'client detached' });
                    this.getServiceClients();
                });
            });
        },
    },
});

export default component;
