




















































import Base from '@/mixins/Base.vue';

import prices from '@/components/Prices.vue';
import { IService } from '@/interfaces/service';

const component = Base.extend({
    components: {
        prices,
    },
    props: {
        service: {
            type: Object as () => IService,
            default: null,
        },
    },
    mounted() {
        this.getPrices();
    },
    data() {
        return {
            prices: undefined as any,
            activeZoneIndex: 'prices.general',
            isAddZoneModalActive: false,
            isEditingZone: false,

        };
    },
    methods: {
        getPrices(): void {
            let url = `services/${this.$route.params.id}/prices`;
            if (this.$route.params.client_id) {
                url = `clients/${this.$route.params.client_id}/services/${this.$route.params.id}/prices`;
            }

            this.get(url, {
                params: {
                    client_id: this.$route.params.client_id,
                },
            }).then(({ data }) => {
                this.prices = data.data;
            });
        },
        savePrices(): void {
            let url = `services/${this.$route.params.id}/prices`;

            if (this.$route.params.client_id) {
                url = `clients/${this.$route.params.client_id}/services/${this.$route.params.id}/prices`;
            }

            this.put(url, {
                prices: this.prices,
            })
                .then(({ data }) => {
                    this.prices = data.data;
                    this.$emit('success', { message: 'Prices saved' });
                });
        },

    },
});

export default component;
