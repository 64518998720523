





























































































































































































































































































import Base from '@/mixins/Base.vue';
import { IService, IServicesResponse } from '@/interfaces/service';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        service: {
            type: Object as () => IService,
            default: null,
        },
    },
    mounted() {
        this.getSubServices();
    },
    data() {
        return {
            params: {
                sortBy: 'id',
                orderBy: 'asc',
                page: 1,

            },
            isAttachSubServiceModalActive: false,
            subservices: {
                data: [] as Array<IService>,
                meta: {} as IPagination | any,
            } as IServicesResponse,
            linkedSubservices: [] as Array<IService>,
            quantity: 1,
        };
    },
    methods: {
        getSubServices(page: number = 1): void {
            this.params.page = page;

            let url = `services/${this.$route.params.id}/sub-services`;

            if (this.$route.params.client_id) {
                url = `clients/${this.$route.params.client_id}/services/${this.$route.params.id}/sub-services`;
            }

            this.get<IServicesResponse>(url, {
                params: {
                    ...this.params,
                    client_id: this.service.client_service ? this.service.client_service.client_id : null,
                },
            }).then(({ data }) => {
                this.subservices = data;
                this.service.children_count = this.subservices.meta.total;
                this.service.has_subservices = this.subservices.meta.total > 0;
            });
        },
        attachSubservices(): void {
            this.post(`services/${this.$route.params.id}/sub-services`, {
                service_ids: this.linkedSubservices.map((p:IService) => p.id),
                quantity: this.quantity,
            }).then(() => {
                this.$emit('success', { message: 'subservice created' });
                this.getSubServices();
                this.isAttachSubServiceModalActive = false;

                this.linkedSubservices = [];
            });
        },
        updateSubservice(service: IService): void {
            this.put(`services/${this.$route.params.id}/sub-services/${service.id}`, {
                quantity: service.service_children.quantity,
            }).then(() => {
                this.$emit('success', { message: 'subservice created' });
                this.getSubServices();
                this.isAttachSubServiceModalActive = false;

                this.linkedSubservices = [];
            });
        },
        detachSubservice(subservice: IService): void {
            this.confirm().then(() => {
                this.delete(`services/${this.$route.params.id}/sub-services/${subservice.id}`).then(() => {
                    this.$emit('success', { message: 'subservice detached' });
                    this.getSubServices();
                });
            });
        },
    },
});

export default component;
